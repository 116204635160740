// CalorieCalculator.jsx

import './calorie_calculator.css';
import React from 'react';
import {
    TextField,
    InputAdornment,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Typography,
    Button,
    Slider,
} from '@material-ui/core';
import { UserContext } from 'contexts/user_context';
import { calculateProjections } from './projection_calculations';
import WeightProjectionGraph from './weight_projection_graph';
import MacroSlider from './macro_slider';

// Import ToggleButton components and withStyles
import { withStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

// Import helper function for date formatting
import { getDateMonthYear } from 'helpers/date_helpers';

// Import Alert for displaying validation messages
import { Alert } from '@material-ui/lab';

export const activityMultiplierList = [
    { name: 'Sedentary', value: 1.2 },
    { name: 'Lightly active', value: 1.3 },
    { name: 'Moderately active', value: 1.4 },
    { name: 'Very active', value: 1.5 },
];

export const exerciseMultiplierList = [
    { name: 'Little or no exercise', value: 1.0 },
    { name: 'Light exercise (1-3 days/week)', value: 1.1 },
    { name: 'Moderate exercise (3-5 days/week)', value: 1.2 },
    { name: 'Heavy exercise (6-7 days/week)', value: 1.3 },
    { name: 'Very heavy exercise (twice a day)', value: 1.4 },
];

export const goalMultiplierList = [
    { name: 'Lose weight', value: 0.8 },
    { name: 'Maintain weight', value: 1 },
    { name: 'Build Muscle', value: 1.08 },
];

const genderList = [
    { name: 'Female', value: 'female' },
    { name: 'Male', value: 'male' },
];

// Styled ToggleButton component
const StyledToggleButton = withStyles({
    root: {
        '&$selected': {
            backgroundColor: 'rgba(76, 184, 133, 0.9)',
            color: 'rgba(255, 255, 255)',
            '&:hover': {
                backgroundColor: 'rgba(76, 184, 133, 0.9)',
            },
        },
        fontSize: '11px',
    },
    selected: {},
})(ToggleButton);

// Styled Slider for Total Daily Calories with white font in value label
const WhiteFontSlider = withStyles({
    valueLabel: {
        '& *': {
            color: 'white',
            background: 'rgb(76, 184, 133)',
        },
    },
})(Slider);

const CalorieCalculator = (props) => {
    const userContext = React.useContext(UserContext);
    const { userObject, setUserObject, getCurrentWeight, getCurrentBodyFat } = userContext;

    const [step, setStep] = React.useState(1);

    // Step 1 variables
    const [sex, setSex] = React.useState(userObject.sex || '');
    const [age, setAge] = React.useState(userObject.age || '');
    const [height, setHeight] = React.useState(userObject.height || '');
    const [heightUnit, setHeightUnit] = React.useState(userObject.heightUnit || 'ft'); // Default to 'ft' instead of 'cm'
    const [heightFeet, setHeightFeet] = React.useState(userObject.heightFeet || '');
    const [heightInches, setHeightInches] = React.useState(userObject.heightInches || '');

    const [currentWeight, setCurrentWeight] = React.useState(
        parseFloat(getCurrentWeight()[0]) || ''
    );
    const [weightUnit, setWeightUnit] = React.useState(getCurrentWeight()[1] || 'lbs');
    const [currentBodyFat, setCurrentBodyFat] = React.useState(
        parseFloat(getCurrentBodyFat()) || ''
    );

    // State variables for error messages
    const [currentBodyFatError, setCurrentBodyFatError] = React.useState('');
    const [goalBodyFatError, setGoalBodyFatError] = React.useState('');

    // Step 2 variables
    const [goalWeight, setGoalWeight] = React.useState(
        userObject.weightGoal ? userObject.weightGoal.value : ''
    );
    const [goalBodyFat, setGoalBodyFat] = React.useState(
        userObject.bodyFatGoal !== undefined ? userObject.bodyFatGoal : ''
    );

    const [activityLevel, setActivityLevel] = React.useState(userObject.activityMultiplier || 1.2);
    const [cardioActivity, setCardioActivity] = React.useState(userObject.cardioModifier || 1.0);
    const [muscleBuildingActivity, setMuscleBuildingActivity] = React.useState(
        userObject.muscleBuildingModifier || 1.0
    );
    const [dailyCalories, setDailyCalories] = React.useState(userObject.calorieTarget || 2000);
    const [macroDistribution, setMacroDistribution] = React.useState(
        userObject.macroDistribution || { protein: 30, carbs: 40, fat: 30 }
    );

    const [projections, setProjections] = React.useState([]);
    const [warningMessage, setWarningMessage] = React.useState('');
    const [projectedGoalDate, setProjectedGoalDate] = React.useState('');
    const [projectedBodyFatGoalDate, setProjectedBodyFatGoalDate] = React.useState('');
    const [weightGoalWeek, setWeightGoalWeek] = React.useState(null);
    const [bodyFatGoalWeek, setBodyFatGoalWeek] = React.useState(null);

    // State variables for final projections
    const [finalProjectedWeight, setFinalProjectedWeight] = React.useState(null);
    const [finalProjectedBodyFat, setFinalProjectedBodyFat] = React.useState(null);

    // State variable to control plan generation
    const [planGenerated, setPlanGenerated] = React.useState(false);

    const handleNext = (e) => {
        e.preventDefault(); // Prevent default form submission
        if (step === 1) {
            // Validation for step 1
            if (!sex || !age || (!height && !(heightFeet && heightInches)) || !currentWeight) {
                alert('Please fill all required fields.');
                return;
            }

            // Determine essential body fat based on sex
            const essentialBodyFat = sex === 'male' ? 5 : 12;

            if (currentBodyFat && (currentBodyFat < essentialBodyFat || currentBodyFat > 60)) {
                alert(
                    `Please enter a valid current body fat percentage between ${essentialBodyFat}% and 60%.`
                );
                return;
            }

            // Prepare the date key
            const dateKey = getDateMonthYear(new Date());

            // Create an updated userObject without setting undefined values
            const updatedUserObject = {
                ...userObject,
                sex,
                age,
                height,
                heightUnit,
                heightFeet,
                heightInches,
                weightUnit,
                weight: {
                    ...userObject.weight,
                    [dateKey]: {
                        value: parseFloat(currentWeight),
                        unit: weightUnit,
                    },
                },
            };

            // Only add bodyFat if currentBodyFat is provided
            if (currentBodyFat !== '' && currentBodyFat !== undefined) {
                updatedUserObject.bodyFat = {
                    ...userObject.bodyFat,
                    [dateKey]: parseFloat(currentBodyFat),
                };
            }

            setUserObject(updatedUserObject);
            setStep(2);
        } else if (step === 2) {
            // Handle submit only if planGenerated is true
            if (planGenerated) {
                handleSubmit(e);
            } else {
                alert('Please generate a plan before proceeding.');
            }
        }
    };

    const handleBack = (e) => {
        e.preventDefault(); // Prevent default form submission
        if (step === 2 && planGenerated) {
            // If on step 2 and plan is generated, reset planGenerated
            setPlanGenerated(false);
        } else if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleSkip = (e) => {
        e.preventDefault(); // Prevent default form submission

        // Prepare the date key
        const dateKey = getDateMonthYear(new Date());

        let updatedUserObject = {
            ...userObject,
            sex,
            age,
            height,
            heightUnit,
            heightFeet,
            heightInches,
            weightUnit,
            weight: {
                ...userObject.weight,
                [dateKey]: {
                    value: parseFloat(currentWeight),
                    unit: weightUnit,
                },
            },
        };

        // Only add bodyFat if currentBodyFat is provided
        if (currentBodyFat !== '' && currentBodyFat !== undefined) {
            updatedUserObject.bodyFat = {
                ...userObject.bodyFat,
                [dateKey]: parseFloat(currentBodyFat),
            };
        }

        if (planGenerated) {
            // Calculate proteins, carbs, fats in grams
            const proteinGrams = Math.round((dailyCalories * (macroDistribution.protein / 100)) / 4);
            const carbGrams = Math.round((dailyCalories * (macroDistribution.carbs / 100)) / 4);
            const fatGrams = Math.round((dailyCalories * (macroDistribution.fat / 100)) / 9);
            const roundedCalories = Math.round(dailyCalories);

            // Update the user object with the new values
            updatedUserObject = {
                ...updatedUserObject,
                weightGoal: {
                    value: parseFloat(goalWeight),
                    unit: weightUnit,
                },
                projectedGoalDate,
                calorieTarget: dailyCalories,
                macroDistribution,
                activityMultiplier: activityLevel,
                cardioModifier: cardioActivity,
                muscleBuildingModifier: muscleBuildingActivity,
                proteins: proteinGrams,
                carbs: carbGrams,
                fats: fatGrams,
                calories: roundedCalories,
            };

            // Only add bodyFatGoal if goalBodyFat is provided
            if (goalBodyFat !== '' && goalBodyFat !== undefined) {
                updatedUserObject.bodyFatGoal = parseFloat(goalBodyFat);
            }
        }

        setUserObject(updatedUserObject);

        props.skip();
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        // Calculate proteins, carbs, fats in grams
        const proteinGrams = Math.round((dailyCalories * (macroDistribution.protein / 100)) / 4);
        const carbGrams = Math.round((dailyCalories * (macroDistribution.carbs / 100)) / 4);
        const fatGrams = Math.round((dailyCalories * (macroDistribution.fat / 100)) / 9);
        const roundedCalories = Math.round(dailyCalories);

        // Create an updated userObject without setting undefined values
        const updatedUserObject = {
            ...userObject,
            weightGoal: {
                value: parseFloat(goalWeight),
                unit: weightUnit,
            },
            projectedGoalDate,
            calorieTarget: dailyCalories,
            macroDistribution,
            activityMultiplier: activityLevel,
            cardioModifier: cardioActivity,
            muscleBuildingModifier: muscleBuildingActivity,
            proteins: proteinGrams,
            carbs: carbGrams,
            fats: fatGrams,
            calories: roundedCalories,
        };

        // Only add bodyFatGoal if goalBodyFat is provided
        if (goalBodyFat !== '' && goalBodyFat !== undefined) {
            updatedUserObject.bodyFatGoal = parseFloat(goalBodyFat);
        }

        setUserObject(updatedUserObject);
        props.handleExitClick();
    };

    React.useEffect(() => {
        if (step === 2 && planGenerated) {
            recalculateProjections();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        planGenerated,
        currentWeight,
        goalWeight,
        currentBodyFat,
        goalBodyFat,
        dailyCalories,
        macroDistribution,
        activityLevel,
        cardioActivity,
        muscleBuildingActivity,
        sex,
        age,
        height,
        heightUnit,
        heightFeet,
        heightInches,
    ]);

    React.useEffect(() => {
        if (planGenerated) {
            const updatedUserObject = {
                ...userObject,
                weightGoal: {
                    value: parseFloat(goalWeight),
                    unit: weightUnit,
                },
                calorieTarget: dailyCalories,
                macroDistribution,
                activityMultiplier: activityLevel,
                cardioModifier: cardioActivity,
                muscleBuildingModifier: muscleBuildingActivity,
            };

            // Only add bodyFatGoal if goalBodyFat is provided
            if (goalBodyFat !== '' && goalBodyFat !== undefined) {
                updatedUserObject.bodyFatGoal = parseFloat(goalBodyFat);
            }

            setUserObject(updatedUserObject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        planGenerated,
        activityLevel,
        cardioActivity,
        muscleBuildingActivity,
        dailyCalories,
        macroDistribution,
        goalWeight,
        goalBodyFat,
    ]);

    const handleCurrentBodyFatChange = (e) => {
        const value = e.target.value;
        setCurrentBodyFat(value);

        // Determine essential body fat based on sex
        const essentialBodyFat = sex === 'male' ? 5 : 12;

        if (value !== '' && (value < essentialBodyFat || value > 60)) {
            setCurrentBodyFatError(
                `Please enter a value between ${essentialBodyFat}% and 60%.`
            );
        } else {
            setCurrentBodyFatError('');
        }
    };

    const handleGoalBodyFatChange = (e) => {
        const value = e.target.value;
        setGoalBodyFat(value);

        // Determine essential body fat based on sex
        const essentialBodyFat = sex === 'male' ? 5 : 12;

        if (value !== '' && (value < essentialBodyFat || value > 60)) {
            setGoalBodyFatError(
                `Please enter a value between ${essentialBodyFat}% and 60%.`
            );
        } else {
            setGoalBodyFatError('');
        }
    };

    const handleGeneratePlan = (e) => {
        e.preventDefault(); // Prevent default form submission
        // Validation for goalWeight and goalBodyFat
        if (!goalWeight) {
            alert('Please enter your goal weight.');
            return;
        }

        // Determine essential body fat based on sex
        const essentialBodyFat = sex === 'male' ? 5 : 12;

        if (goalBodyFat && (goalBodyFat < essentialBodyFat || goalBodyFat > 60)) {
            alert(
                `Please enter a valid goal body fat percentage between ${essentialBodyFat}% and 60%.`
            );
            return;
        }

        // Use user inputted activity levels
        const userActivityLevel = parseFloat(activityLevel);
        const userCardioActivity = parseFloat(cardioActivity);
        const userMuscleBuildingActivity = parseFloat(muscleBuildingActivity);

        // Calculate dailyCalories based on BMR and TDEE
        let weightUnit = userObject.weightUnit || 'lbs';
        let heightUnit = userObject.heightUnit || 'cm';

        let currentWeightKg =
            weightUnit === 'kg' ? parseFloat(currentWeight) : parseFloat(currentWeight) * 0.453592;

        let heightCm;
        if (heightUnit === 'cm') {
            heightCm = parseFloat(userObject.height);
        } else {
            // Convert feet and inches to cm
            heightCm =
                ((parseFloat(userObject.heightFeet || 0) * 12) +
                    parseFloat(userObject.heightInches || 0)) *
                2.54;
        }

        // Basal Metabolic Rate (BMR)
        const age = parseInt(userObject.age) || 30;

        let BMR;
        if (sex === 'male') {
            BMR = 10 * currentWeightKg + 6.25 * heightCm - 5 * age + 5;
        } else {
            BMR = 10 * currentWeightKg + 6.25 * heightCm - 5 * age - 161;
        }

        // Total Daily Energy Expenditure (TDEE)
        const TDEE =
            BMR * userActivityLevel * userCardioActivity * userMuscleBuildingActivity;

        // Set dailyCalories to TDEE adjusted for weight goal
        let dailyCalorieIntake;
        if (parseFloat(currentWeight) > parseFloat(goalWeight)) {
            // Weight loss
            dailyCalorieIntake = TDEE - 500;
        } else if (parseFloat(currentWeight) < parseFloat(goalWeight)) {
            // Weight gain
            dailyCalorieIntake = TDEE + 500;
        } else {
            // Maintenance
            dailyCalorieIntake = TDEE;
        }

        // Ensure dailyCalories is not below minCalories
        const minCalories = sex === 'male' ? 1300 : 1000;
        if (dailyCalorieIntake < minCalories) {
            dailyCalorieIntake = minCalories;
        }

        setDailyCalories(Math.round(dailyCalorieIntake));
        setMacroDistribution(userObject.macroDistribution || { protein: 30, carbs: 40, fat: 30 });

        // Update userObject with the generated plan
        const updatedUserObject = {
            ...userObject,
            activityMultiplier: userActivityLevel,
            cardioModifier: userCardioActivity,
            muscleBuildingModifier: userMuscleBuildingActivity,
            calorieTarget: Math.round(dailyCalorieIntake),
            macroDistribution: userObject.macroDistribution || { protein: 30, carbs: 40, fat: 30 },
        };

        // Only add bodyFatGoal if goalBodyFat is provided
        if (goalBodyFat !== '' && goalBodyFat !== undefined) {
            updatedUserObject.bodyFatGoal = parseFloat(goalBodyFat);
        }

        setUserObject(updatedUserObject);

        // Set planGenerated to true
        setPlanGenerated(true);
    };

    const recalculateProjections = () => {
        const {
            dataPoints,
            goalDate,
            bodyFatGoalDate,
            warning,
            weightGoalMetWeek,
            bodyFatGoalMetWeek,
            finalWeight,
            finalBodyFat,
        } = calculateProjections({
            currentWeight: parseFloat(currentWeight),
            goalWeight: parseFloat(goalWeight),
            currentBodyFat: currentBodyFat !== '' ? parseFloat(currentBodyFat) : null,
            goalBodyFat: goalBodyFat !== '' ? parseFloat(goalBodyFat) : null,
            dailyCalories: parseFloat(dailyCalories),
            macroDistribution,
            activityLevel: parseFloat(activityLevel),
            cardioActivity: parseFloat(cardioActivity),
            muscleBuildingActivity: parseFloat(muscleBuildingActivity),
            userObject: {
                sex,
                age,
                height,
                heightUnit,
                heightFeet,
                heightInches,
                weightUnit,
            },
        });

        setProjections(dataPoints);
        setProjectedGoalDate(goalDate);
        setProjectedBodyFatGoalDate(bodyFatGoalDate);
        setWarningMessage(warning);
        setWeightGoalWeek(weightGoalMetWeek);
        setBodyFatGoalWeek(bodyFatGoalMetWeek);
        setFinalProjectedWeight(finalWeight);
        setFinalProjectedBodyFat(finalBodyFat);
    };

    // Define options for toggle buttons
    const activityLevelOptions = [
        { value: 1.2, label: 'Sedentary' },
        { value: 1.3, label: 'Lightly Active' },
        { value: 1.4, label: 'Moderately Active' },
        { value: 1.5, label: 'Very Active' },
    ];

    const cardioActivityOptions = [
        { value: 1.0, label: '0-1 / week' },
        { value: 1.1, label: '2-3 / week' },
        { value: 1.2, label: '4-5 / week' },
        { value: 1.3, label: '6-7 / week' },
    ];

    const muscleBuildingActivityOptions = [
        { value: 1.0, label: '0-1 / week' },
        { value: 1.1, label: '2-3 / week' },
        { value: 1.2, label: '4-5 / week' },
        { value: 1.3, label: '6-7 / week' },
    ];

    const minCalories = sex === 'male' ? 1300 : 1000;

    return (
        <div className="calorie-calculator-form">
            {step === 1 && (
                <form onSubmit={handleNext}>
                    <div
                        style={{
                            fontSize: '18px',
                            marginTop: '-10px',
                        }}
                        className="onboarding-wizard-title"
                    >
                        Tell us about
                        <b style={{ color: '#2D7933' }}> yourself</b>
                    </div>

                    <div className="calorie-calculator-form-line-item">
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="sex-label">Gender</InputLabel>
                            <Select
                                labelId="sex-label"
                                value={sex}
                                onChange={(e) => setSex(e.target.value)}
                                label="Gender"
                            >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="calorie-calculator-form-line-item-text">
                        <TextField
                            label="Age"
                            type="number"
                            variant="outlined"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                        />
                    </div>
                    {/* Height */}
                    {heightUnit === 'cm' ? (
                        <div className="calorie-calculator-form-line-item-text">
                            <TextField
                                label="Height"
                                type="number"
                                variant="outlined"
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Select
                                                value={heightUnit}
                                                onChange={(e) => setHeightUnit(e.target.value)}
                                                disableUnderline
                                            >
                                                <MenuItem value="cm">cm</MenuItem>
                                                <MenuItem value="ft">ft/in</MenuItem>
                                            </Select>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    ) : (
                        <div className="calorie-calculator-form-line-item-text">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    label="Height (ft)"
                                    type="number"
                                    variant="outlined"
                                    value={heightFeet}
                                    onChange={(e) => setHeightFeet(e.target.value)}
                                />
                                <TextField
                                    label="Height (in)"
                                    type="number"
                                    variant="outlined"
                                    value={heightInches}
                                    onChange={(e) => setHeightInches(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Select
                                                    value={heightUnit}
                                                    onChange={(e) => setHeightUnit(e.target.value)}
                                                    disableUnderline
                                                >
                                                    <MenuItem value="cm">cm</MenuItem>
                                                    <MenuItem value="ft">ft/in</MenuItem>
                                                </Select>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {/* Current Weight */}
                    <div className="calorie-calculator-form-line-item-text">
                        <TextField
                            label="Current Weight"
                            type="number"
                            variant="outlined"
                            value={currentWeight}
                            onChange={(e) => setCurrentWeight(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Select
                                            value={weightUnit}
                                            onChange={(e) => setWeightUnit(e.target.value)}
                                            disableUnderline
                                        >
                                            <MenuItem value="lbs">lbs</MenuItem>
                                            <MenuItem value="kg">kg</MenuItem>
                                        </Select>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    {/* Current Body Fat % */}
                    <div className="calorie-calculator-form-line-item-text">
                        <TextField
                            label="Current Body Fat % (optional)"
                            type="number"
                            variant="outlined"
                            value={currentBodyFat}
                            onChange={handleCurrentBodyFatChange}
                            error={!!currentBodyFatError}
                            helperText={currentBodyFatError}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="calorie-calculator-form-buttons">
                        {props.skip && (
                            <Button onClick={handleSkip} color="default">
                                Skip
                            </Button>
                        )}
                        <button
                            type="submit"
                            className="submit-button"
                            variant="contained"
                            color="primary"
                        >
                            Next
                        </button>
                    </div>
                </form>
            )}
            {step === 2 && (
                <form onSubmit={handleNext}>
                    <div
                        style={{
                            fontSize: '18px',
                            marginTop: '-10px',
                        }}
                        className="onboarding-wizard-title"
                    >
                        Set your
                        <b style={{ color: '#2D7933' }}> goals</b>
                    </div>
                    {/* Goal Weight */}
                    <div className="calorie-calculator-form-line-item-text">
                        <TextField
                            label="Goal Weight"
                            type="number"
                            variant="outlined"
                            value={goalWeight}
                            onChange={(e) => setGoalWeight(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Select
                                            value={weightUnit}
                                            onChange={(e) => setWeightUnit(e.target.value)}
                                            disableUnderline
                                        >
                                            <MenuItem value="lbs">lbs</MenuItem>
                                            <MenuItem value="kg">kg</MenuItem>
                                        </Select>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    {/* Goal Body Fat % */}
                    <div className="calorie-calculator-form-line-item-text">
                        <TextField
                            label="Goal Body Fat % (optional)"
                            type="number"
                            variant="outlined"
                            value={goalBodyFat}
                            onChange={handleGoalBodyFatChange}
                            error={!!goalBodyFatError}
                            helperText={goalBodyFatError}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </div>
                    {/* Daily Lifestyle Toggle Buttons */}
                    <div className="calorie-calculator-form-line-item-text">
                        <Typography id="activity-level-toggle" gutterBottom>
                            Daily Lifestyle
                        </Typography>
                        <ToggleButtonGroup
                            value={activityLevel}
                            exclusive
                            onChange={(e, newValue) => {
                                if (newValue !== null) {
                                    setActivityLevel(newValue);
                                    setUserObject({ ...userObject, activityMultiplier: newValue });
                                }
                            }}
                            aria-label="activity level"
                            style={{ marginTop: '10px' }}
                        >
                            {activityLevelOptions.map((option) => (
                                <StyledToggleButton
                                    key={option.value}
                                    value={option.value}
                                    aria-label={option.label}
                                >
                                    {option.label}
                                </StyledToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                    {/* Cardio Activity Toggle Buttons */}
                    <div className="calorie-calculator-form-line-item-text">
                        <Typography id="cardio-activity-toggle" gutterBottom>
                            Cardio Exercise Days
                        </Typography>
                        <ToggleButtonGroup
                            value={cardioActivity}
                            exclusive
                            onChange={(e, newValue) => {
                                if (newValue !== null) {
                                    setCardioActivity(newValue);
                                    setUserObject({ ...userObject, cardioModifier: newValue });
                                }
                            }}
                            aria-label="cardio activity"
                            style={{ marginTop: '10px' }}
                        >
                            {cardioActivityOptions.map((option) => (
                                <StyledToggleButton
                                    key={option.value}
                                    value={option.value}
                                    aria-label={option.label}
                                >
                                    {option.label}
                                </StyledToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                    {/* Muscle Building Activity Toggle Buttons */}
                    <div className="calorie-calculator-form-line-item-text">
                        <Typography id="muscle-building-activity-toggle" gutterBottom>
                            Muscle Building Activity Days
                        </Typography>
                        <ToggleButtonGroup
                            value={muscleBuildingActivity}
                            exclusive
                            onChange={(e, newValue) => {
                                if (newValue !== null) {
                                    setMuscleBuildingActivity(newValue);
                                    setUserObject({ ...userObject, muscleBuildingModifier: newValue });
                                }
                            }}
                            aria-label="muscle building activity"
                            style={{ marginTop: '10px' }}
                        >
                            {muscleBuildingActivityOptions.map((option) => (
                                <StyledToggleButton
                                    key={option.value}
                                    value={option.value}
                                    aria-label={option.label}
                                >
                                    {option.label}
                                </StyledToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                    {!planGenerated && (
                        <>
                            <div
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    fontSize: '12px',
                                    color: 'grey',
                                    marginLeft: '15px',
                                    textAlign: 'center',
                                }}
                            >
                                This calculator works off of broad demographic data. To get recommendations tailored
                                to you we recommend using our AI consultant every 2 weeks while tracking.
                            </div>
                            <div className="calorie-calculator-form-buttons">
                                <button
                                    type="button"
                                    className="cancel-button"
                                    style={{ marginRight: '5px' }}
                                    onClick={handleBack}
                                >
                                    Back
                                </button>
                                {props.skip && (
                                    <Button onClick={handleSkip} color="default">
                                        Skip
                                    </Button>
                                )}
                                <button
                                    type="button"
                                    className="submit-button"
                                    style={{ marginLeft: '5px', background:'rgb(76, 184, 133)' }}
                                    onClick={handleGeneratePlan}
                                >
                                    Generate a Plan
                                </button>
                            </div>
                        </>
                    )}
                    {planGenerated && (
                        <>
                            {/* Total Daily Calories Slider */}
                            <div className="calorie-calculator-form-line-item-text">
                                <Typography id="daily-calories-slider" gutterBottom>
                                    Daily Caloric Intake -{' '}
                                    <b style={{ color: 'rgba(76, 184, 133,1)' }}>{dailyCalories} cals</b>
                                </Typography>
                                <WhiteFontSlider
                                    value={dailyCalories}
                                    onChange={(e, val) => {
                                        setDailyCalories(val);
                                        setUserObject({ ...userObject, calorieTarget: val });
                                    }}
                                    step={10} // Changed to increment/decrement by 10
                                    min={minCalories}
                                    max={4000}
                                />
                            </div>
                            {/* Macro Distribution */}
                            <div className="calorie-calculator-form-line-item-text">
                                <MacroSlider
                                    macroDistribution={macroDistribution}
                                    setMacroDistribution={(newMacroDistribution) => {
                                        setMacroDistribution(newMacroDistribution);
                                        setUserObject({ ...userObject, macroDistribution: newMacroDistribution });
                                    }}
                                    dailyCalories={dailyCalories}
                                />
                            </div>

                            {/* Warning Message */}
                            {warningMessage && (
                                <Typography
                                    variant="body2"
                                    color="error"
                                    align="center"
                                    style={{ marginBottom: '7px' }}
                                    dangerouslySetInnerHTML={{ __html: warningMessage }}
                                />
                            )}

                            {/* Projected Goal Dates or Final Projections */}
                            {planGenerated && finalProjectedWeight && !isNaN(finalProjectedWeight) && (
                                <Typography variant="body2" align="center">
                                    {weightGoalWeek !== null ? (
                                        <>
                                            We estimate you will reach your weight goal by{' '}
                                            <strong>{projectedGoalDate}</strong>.
                                        </>
                                    ) : (
                                        <>
                                            Based on your current selection, in one year, you can expect to weigh approximately{' '}
                                            <strong>
                                                {finalProjectedWeight.toFixed(1)} {weightUnit}
                                            </strong>
                                            .
                                        </>
                                    )}
                                    {goalBodyFat && finalProjectedBodyFat && !isNaN(finalProjectedBodyFat) && (
                                        <>
                                            {' '}
                                            {bodyFatGoalWeek !== null ? (
                                                <>
                                                    Your body fat goal is expected to be reached{' '}
                                                    {projectedBodyFatGoalDate !== projectedGoalDate ? (
                                                        <>
                                                            by <strong>{projectedBodyFatGoalDate}</strong>.
                                                        </>
                                                    ) : (
                                                        'around the same time.'
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    In one year, your body fat percentage is projected to be{' '}
                                                    <strong>{finalProjectedBodyFat.toFixed(1)}%</strong>.
                                                </>
                                            )}
                                        </>
                                    )}
                                </Typography>
                            )}

                            {/* Weight Projection Graph */}
                            <div style={{ marginTop: '20px' }}>
                                <WeightProjectionGraph data={projections} weightUnit={weightUnit} />
                            </div>
                            {/* Buttons */}
                            <div className="calorie-calculator-form-buttons">
                                <button
                                    type="button"
                                    className="cancel-button"
                                    variant="outlined"
                                    color="default"
                                    onClick={handleBack}
                                    style={{ marginRight: '10px' }}
                                >
                                    Back
                                </button>
                                {props.skip && (
                                    <Button onClick={handleSkip} color="default">
                                        Skip
                                    </Button>
                                )}
                                <button
                                    type="submit"
                                    className="submit-button"
                                    style={{ background: 'rgba(76, 184, 133, 0.9)', marginLeft: '10px' }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save and Continue
                                </button>
                            </div>
                        </>
                    )}
                </form>
            )}
        </div>
    );
};

export default CalorieCalculator;
